import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { createForm } from 'rc-form';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Layout from '../../../components/v2/Layout';
import { getEmoriRelationCorporateList, postEmoriRelationVerify } from '../../../apis/emori';

const useStyles = makeStyles(theme => ({
  textfield: {
    fontSize: '13px',
    fontWeight: 'bold',
  },
  topic: {
    marginBottom: '7px',
    fontSize: '22px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    color: 'black',
  },
  comment: {
    marginTop: '13px',
    marginBottom: '5px',
    fontSize: '11px',
    color: 'black',
    padding: '4px',
  },
  input: {
    borderRadius: '8px',
    height: '12px',
    fontSize: '0.8rem',
  },
}));

const RegisterCorp = ({ form, readOnly, loading, location }) => {
  const classes = useStyles();
  const { validateFields, getFieldDecorator, getFieldError } = form;
  const [ corporate, setCorporate ] = React.useState([]);

  React.useEffect(() => {
    getCorp();
  }, []);

  const getCorp = async () => {
    try {
      const member = await getEmoriRelationCorporateList('false');
      const corporateList = member.results;
      setCorporate(corporateList);
    } catch (e) {}
  }

  const onSubmit = async (corporateId, payload) => {
    await postEmoriRelationVerify(corporateId, payload);
    await navigate(`/emori/relation/createRoom`, { replace: true });
  }

  const register = () => {
    validateFields((error, value) => {
      if (!error) {
        const corpId = value.corporate;
        const payload = {
          username: value.username,
          verify_code: value.password,
        }
        onSubmit(corpId, payload);
      }
    });
  }

  if (!corporate) return false;

  return (
    <Layout bg='bg4' title='ลงทะเบียนองค์กร'>
      <Typography className={classes.topic}>Log-in เข้าสู่ระบบ</Typography>
      <Table classes={{ root: classes.tableSpace }}>
        <TableBody>
          <TableRow>
          <Cell style={{ minWidth: '100px' }}><span className={classes.textfield}>เลือกองค์กร</span></Cell>
            <Cell>
              {
                getFieldDecorator('corporate', {
                  rules: [{ required: true }],
                })(
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    fullWidth
                    size='small'
                    select
                    SelectProps={{
                      native: true,
                    }}
                    inputProps={{
                      className: classes.input
                    }}
                    disabled={readOnly}
                    error={getFieldError('corporate')}
                  >
                    <option value=''></option>
                    {corporate.map((option) => (
                      <option value={option.id} key={option.id}>
                        {option.corporate.name}
                      </option>
                    ))}
                  </TextField>
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.textfield}>Username :</span></Cell>
            <Cell>
              {
                getFieldDecorator('username', {
                  rules: [{ required: true }],
                })(
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    fullWidth
                    size='small'
                    inputProps={{
                      className: classes.input
                    }}
                    disabled={readOnly}
                    error={getFieldError('username')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}><span className={classes.textfield}>Password :</span></Cell>
            <Cell>
              {
                getFieldDecorator('password', {
                  rules: [{ required: true }],
                })(
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    fullWidth
                    size='small'
                    inputProps={{
                      className: classes.input
                    }}
                    disabled={readOnly}
                    error={getFieldError('password')}
                  />
                )
              }
            </Cell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography className={classes.comment}>กรุณาล็อคอินเข้าระบบ โดยใช้ username และ password ที่ได้รับจากเจ้าหน้าที่</Typography>
      <Button variant='contained' color='primary' fullWidth onClick={register}>
        ยืนยัน
      </Button>
    </Layout>
  );
};

export default createForm()(RegisterCorp);